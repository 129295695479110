import React from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PaymentOutcomePage = () => {
    const query = useQuery();
    const success = query.get('success') === 'true';

    const emojy = success ? '🙂' : '🙁';
    const messageBig = success ? 'Payment succeeded' : 'Payment failed';
    const messageSmall = success ? 'Brewing will start shortly...' : 'An error occurred while processing your payment';

    return <div className="error-box">
        <span className="error-code">{emojy}</span>
        <p className="error-message">{messageBig}</p>
        <p className="error-message error-message--small">{messageSmall}</p>
    </div>
}

export default PaymentOutcomePage