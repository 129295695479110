import React, {useEffect, useState} from 'react';
import { fetchRecipes, brewRecipe, payRecipe } from './BrewApi';
import './BrewPage.css'
import {toast} from 'react-toastify'
import NumberFormat from 'react-number-format';

const BrewPage = ({match}) => {
    useEffect(() => {
        const url = new URL(document.location);
        const searchParams = url.searchParams;
        const showConfirmation = searchParams.has('success') && searchParams.get('success')
        if (showConfirmation) {
            toast.success('Your request has been sent successfully!')
        }
    }, [])

    const [recipes, setRecipes] = useState([])
    const [machineIsAvailable, setMachineIsAvailable] = useState(true)
    const [iconSet, setIconSet] = useState('armonia')

    const sessionId = match.params.serial;

    const handleBrewRecipe = async recipe => {
        if (!window.confirm(`Do you want a ${recipe.label}?`)) {
            return
        }

        let response
        try {
            if (recipe.price > 0) {
                toast.success('Your request has been sent successfully!')
                response = await payRecipe(sessionId, recipe.number)
                window.location = response.redirectUrl;
            } else {
                response = await brewRecipe(sessionId, recipe.number)
            }
        } catch (err) {
            console.error(err)
            toast.error('Session expired.')
            return
        }

        if (typeof response.machineReady !== 'undefined' && !response.machineReady) {
            toast.error('Machine is currently not ready. Your recipe may not be brewed.')
        }
        toast.success('Your request has been sent successfully!')
    }

    useEffect(() => {
        fetchRecipes(sessionId).then(response => {
            setMachineIsAvailable(response.machineIsAvailable)
            setRecipes(response.recipes)
            setIconSet(response?.iconSet ?? 'armonia')
        })
    }, [sessionId])

    if (recipes.length === 0) {
        return null
    }

    if (!machineIsAvailable) {
        return <div className="error-box">
            <span className="error-code">:(</span>
            Touchless non disponibile
        </div>
    }

    return <div className="recipes">
        {recipes.map((recipe, key) => <Recipe key={key} recipe={recipe} iconSet={iconSet} onRecipeClick={handleBrewRecipe}/>)}
    </div>
}


const iconUrlForRecipe = (recipe, iconSet) => {
    iconSet = iconSet ?? recipe.type;
    const icon = (iconSet === 'armonia' || iconSet === 'silverace') ? `Drink${recipe.icon}.png` : `Ico_${recipe.icon}.png`;

    return `${process.env.PUBLIC_URL}/icons/${iconSet}/${icon}`;
}

const Recipe = ({recipe, iconSet, onRecipeClick}) => <div className="recipe">
    <div className="recipe__content" onClick={() => onRecipeClick(recipe)}>
        <div className="recipe__icon" style={{backgroundImage: `url("${iconUrlForRecipe(recipe, iconSet)}")`}}/>
        <div className="recipe__label">{recipe.label}</div>
        {recipe.price > 0 && <div className="recipe__price">
            <NumberFormat value={recipe.price} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} />
        </div>}
    </div>
</div>

export default BrewPage
