import React from 'react';
import QRPage from "./QRPage";
import './App.css';
import logo from './Logo.svg'
import { BrowserRouter , Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BrewPage from './BrewPage'
import PaymentOutcomePage from "./PaymentOutcomePage";

const Homepage = () => <div>Scegli la macchina</div>

const NotFound = () => <div className="error-box">
  <span className="error-code">404</span>
  not found
</div>

const App = () => <div>
  <header className="header">
    <a className="header__logo" href={process.env.PUBLIC_URL} style={{backgroundImage: `url(${logo})`}}> </a>
  </header>

  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route path="/qr/:serial" component={QRPage} />
      <Route exact path="/not-found" component={NotFound} />
      <Route path="/payment-outcome" component={PaymentOutcomePage} />
      <Route path="/:serial" component={BrewPage} />
      <Route path="*" exact component={NotFound} />
    </Switch>
  </BrowserRouter>

  <ToastContainer />
</div>


export default App;
