const resolveEndpoint = (nodeEnv, stage) => {
    if (nodeEnv === 'development') {
        return 'http://localhost:3001/dev';
    }

    const fourthLevel = stage === 'staging' ? 'dev-api' : 'api';

    return `https://${fourthLevel}.brewer.carimali.com`;
}

const endpoint = resolveEndpoint(process.env.NODE_ENV, process.env.REACT_APP_STAGE)

const decodeJson = text => {
    try {
        return JSON.parse(text)
    } catch (err) {
        return {}
    }
}

const fetchRecipes = (sessionId) => {
    return fetch(`${endpoint}/session/${sessionId}`)
        .catch(err => [])
        .then(res => res.status === 200 ? res.json() : { recipes: [] })
}

const brewRecipe = (sessionId, recipe) => {
    return fetch(`${endpoint}/brew/${sessionId}/${recipe}`, {
            method: 'POST'
        })
        .then(response => response.status === 200
            ? response.text()
            : Promise.reject('Status was ' + response.status)
        )
        .then(responseBody => decodeJson(responseBody))
}

const payRecipe = (sessionId, recipe) => {
    return fetch(`${endpoint}/pay/${sessionId}/${recipe}`, {
        method: 'POST'
    })
        .then(response => response.status === 200
            ? response.text()
            : Promise.reject('Status was ' + response.status)
        )
        .then(responseBody => decodeJson(responseBody))
}

export { fetchRecipes, brewRecipe, payRecipe, endpoint }