import React from 'react';
import { endpoint } from "./BrewApi";
import QRCode from 'qrcode.react';
import "./QRPage.css";

/**
 * 1: Create a link without text so that it won’t be visible to users.
 * 2: Assign the image URL and name.
 * 3: Append the link as a child element to the body.
 * 4: Click the created link by javascript to download the image.
 * 5: Remove the created and invisible link
 * 
 * @param serial
 */
const downloadQR = (serial) => {
    const canvas = document.getElementById("generated-qr-code");
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${serial}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export default ({match}) => {
    const serial = match.params.serial;
    const url = `${endpoint}/redirect/${match.params.serial}`;
    const download = () => downloadQR(serial);
    
    return <div className="qr-page">
        <div>
            <QRCode id={"generated-qr-code"} value={url} size={400} includeMargin={true}/>
        </div>
        <div>
            <div className={"download-link"} onClick={download}>Download QR</div>
        </div>
    </div>
}